<div class="modal-header border-0">
  <h4 class="modal-title">{{ 'GENERAL.ATTENTION' | translate }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Descartar"
    (click)="aModal.dismiss()"></button>
</div>
<div class="modal-body py-2">
  @if (!!response) {
    @if (error.length > 0) {
      @for (e of error; track e) {
        <p>{{ e.message }}</p>
      }
    } @else {
      <p>{{ 'GENERAL.OPS_ERROR' | translate }}</p>
    }
  }
</div>
