import { HttpErrorResponse } from '@angular/common/http';

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

// tslint:disable-next-line: interface-over-type-literal
type ServerError = { code: string; message: string };

@Component({
  selector: 'app-modal-server-error',
  templateUrl: './server-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: ['h4 { margin-top: 0; }'],
  standalone: true,
  imports: [TranslateModule],
})
export class ModalServerErrorComponent {
  @Input()
  response: HttpErrorResponse;
  @Input()
  mensaje: string;

  constructor(public aModal: NgbActiveModal) {}

  get error(): ServerError[] {
    const serverErrorList = [];

    if (this.response.error && typeof this.response.error === 'object') {
      const errorCode = Object.keys(this.response.error)[0];
      const errorMessage = this.response.error[errorCode];

      const customErrorMessage = this.response?.error?.NODLT000
        ? 'Este expediente tiene procesos asignados. No puede borrarlo.'
        : errorMessage;

      serverErrorList.push({ code: errorCode, message: customErrorMessage });
    }

    return serverErrorList;
  }

  get status(): number {
    return this.response.status;
  }
}
