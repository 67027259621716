import { HttpResponse } from '@angular/common/http';
import { ICriteriaResponse } from '@models/ICriteriaResponse';
import { OperatorFunction } from 'rxjs';

import { map } from 'rxjs/operators';

export function mapToCriteriaResponse<T>(): OperatorFunction<
  HttpResponse<T[]>,
  ICriteriaResponse<T>
> {
  return map((response: HttpResponse<T[]>) => ({
    body: response.body,
    xTotalCount: Number(response.headers.get('x-total-count') || -1),
  }));
}
